import { render, staticRenderFns } from "./CrmTasks.vue?vue&type=template&id=20ba3752&scoped=true&lang=pug&"
import script from "./CrmTasks.vue?vue&type=script&lang=ts&"
export * from "./CrmTasks.vue?vue&type=script&lang=ts&"
import style0 from "./CrmTasks.vue?vue&type=style&index=0&id=20ba3752&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20ba3752",
  null
  
)

export default component.exports