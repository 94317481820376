var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "crm-tasks _max940"
  }, [_c('div', {
    staticClass: "card"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-content"
  }, [_c('v-checkbox', {
    attrs: {
      "item": "100000003",
      "label": "5 - Besichtigung vereinbart"
    },
    model: {
      value: _vm.whichStatus,
      callback: function callback($$v) {
        _vm.whichStatus = $$v;
      },
      expression: "whichStatus"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "item": "100000012",
      "label": "6 - Besichtigung durchgefuehrt"
    },
    model: {
      value: _vm.whichStatus,
      callback: function callback($$v) {
        _vm.whichStatus = $$v;
      },
      expression: "whichStatus"
    }
  }), _c('ul', _vm._l(_vm.filteredSvBesichtigungTask, function (p) {
    return _c('li', {
      staticClass: "li"
    }, [_c('div', {
      staticClass: "fl"
    }, [_c('router-link', {
      staticClass: "bold",
      attrs: {
        "to": "/crmakt/" + p.aktname
      }
    }, [_vm._v(_vm._s(p.aktname))]), _c('div', {
      staticClass: "thin color-grey"
    }, [_vm._v("(" + _vm._s(p.ageber) + ")")])], 1), _c('div', {
      staticClass: "fr"
    }, [_c('div', {
      staticClass: "tar"
    }, [_c('div', {
      staticClass: "thin"
    }, [_vm._v(_vm._s(_vm.status[p.stid]))]), _c('div', {
      staticClass: "thin color-red"
    }, [_vm._v(_vm._s(_vm.$dayjs(p.besichtigungsdatum).format(_vm.MAIN_DATE_FORMAT))), _c('span', {
      staticClass: "bold color-red"
    }, [_vm._v(" / vor " + _vm._s(_vm.countDays(p)) + " Tagen")])])])])]);
  }), 0)], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "card-header"
  }, [_c('div', {
    staticClass: "tac bold color-red"
  }, [_vm._v("EXPERIMENTELL:")]), _c('h4', [_vm._v("Status 5 und 6 und Besichtigungtermin vorbei:")]), _c('p', [_vm._v("Diese Liste zeigt an, dass die Besichtigung durchgeführt wurde, jedoch der Status noch immer auf 5 steht."), _c('br'), _c('span', [_vm._v("Da Status 6 nicht lange stehen bleiben soll (Fristen der Auftraggeber beachten!), wird dieser ebenfalls dargestellt.")])])]);
}];
export { render, staticRenderFns };